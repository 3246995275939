<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <h1>Персональные данные</h1>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "PersonalData"
}
</script>
